import React from "react";
import { Helmet } from 'react-helmet'
import Navbar from '../Navbar'
import Footer from '../Footer';
// <Navbar />
// <Footer /> 
const Privacypolicy = () => {
  return (
    <>
      <Helmet>
        <title> Probinar - Privacy policy</title>
        <meta
          name="description"
          content="View Probinar's Privacy Policy: Understand how we prioritize data protection and transparency in all interactions."
        ></meta>
        <meta
          name="keywords"
          content="  Privacy policy, best blockchain developer course, course on blockchain, blockchain full course, blockchain classes near me, best blockchain courses for beginners
"
        ></meta>
        <link rel="canonical" href="https://www.probinar.in-privacy-policy" />
        <meta
          property="og:url"
          content="https://www.probinar.in-privacy-policy"
        />
        <meta property="og:title" content=" Probinar - Privacy policy" ></meta>


        <meta property="og:image" content="https://probinar-privacypolicy.s3.amazonaws.com/Privacy+Policy.jpg" ></meta>
        <meta property="og:image:alt" content="  Probinar - Privacy policy" ></meta>

        <meta property="og:site_name" content="  Probinar - Privacy policy" ></meta>
        <meta property="og:description" content=" View Probinar's Privacy Policy: Understand how we prioritize data protection and transparency in all interactions."></meta>
        <meta property="twitter:domain" content="https://www.probinar.in/terms-and-conditions"></meta>


        <meta name="twitter:title" content=" Probinar - Privacy policy" ></meta>
        <meta name="twitter:description" content=" View Probinar's Privacy Policy: Understand how we prioritize data protection and transparency in all interactions." ></meta>
        <meta name="twitter:image" content="https://probinar-privacypolicy.s3.amazonaws.com/Privacy+Policy.jpg" ></meta>
      </Helmet>
      <Navbar />
      <div className="main-content">
        <div className="bred">
          <div className="container">
            <div className="con">
              <h1>Privacy policy</h1>
              <ul>
                <li>Home</li>
                <li>Privacypolicy</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="privcypoli">
          <div class="container rounded shadow col-10 border border-1">
            <div class="p-5">
              <div class="pb-3">
                <h1 fw-bold class="pb-4">
                  <b>Probinar Privacy Policy</b>
                </h1>
                <h2 className="fw-semibold pb-4">
                  Effective Date: <span style={{ color: 'black' }}>December 2024</span>
                </h2>


                <p>
                  At Probinar, we are committed to protecting your privacy and ensuring that your personal data is handled with transparency, security, and care. This Privacy Notice outlines how we collect, use, and safeguard your information when you interact with our services.
                </p>
              </div>
              <div class="pb-3">
                <h2 fw-semibold>Key Information</h2>
                <p>
                  <strong style={{ color: 'black' }}>Probinar</strong> is the data controller for all personal data collected through our platforms. This Privacy Notice includes:
                </p>
                <div>
                  <ul>
                    <li>
                      <strong style={{ color: 'black' }}>What personal data we collect: </strong>Information from registration, course participation, surveys, and interactions.
                    </li>
                    <li>
                      <strong style={{ color: 'black' }}>How we use your data: </strong>For providing services, improving user experience, marketing, and complying with legal obligations.
                    </li>
                    <li>
                      <strong style={{ color: 'black' }}>Your rights:</strong> How to access, update, and manage your data.
                    </li>

                  </ul>
                  <p>For any questions about this notice, please contact us at <strong style={{ color: 'black' }}>info@probinar.in</strong></p>
                </div>
              </div>
              <div class="pb-3">
                <h2 fw-semibold>Purpose and Who We Are</h2>
                {/* <h3 fw-bold>
                  Probinar uses your Personal Information for the following
                  purposes:
                </h3> */}
                {/* <div>
                  <ul>
                    <li>
                      To operate and improve our website and services, including
                      personalizing your learning experience.
                    </li>
                    <li>
                      To create, administer, provide, and teach our blockchain
                      courses.
                    </li>
                    <li>
                      To offer and enhance our products and services, including
                      facilitating course enrollment and providing certificates.
                    </li>
                    <li>
                      To maintain the security and performance of our website
                      and services.
                    </li>
                    <li>
                      To communicate with you, including responding to inquiries
                      and providing updates about our platform.
                    </li>
                    <li>
                      To promote our courses and programs, as permitted by law.
                    </li>
                    <li>
                      To support scientific research in fields such as cognitive
                      science and education.
                    </li>
                    <li>
                      To track website usage and evaluate course performance.
                    </li>
                  </ul>
                </div> */}
                <p>Probinar is a leading blockchain and cryptocurrency education platform offering online courses and resources. Our mission is to empower individuals with the knowledge and skills required to excel in blockchain technology and cryptocurrency trading. This Privacy Notice applies to all our services, including our website, mobile apps, and other digital interfaces.</p>
                <p>For inquiries, please reach out to <strong style={{ color: 'black' }}>info@probinar.in</strong></p>
              </div>
              <div class="pb-3">
                <h2 fw-semibold>What Information This Privacy Notice Covers</h2>
                {/* <h3 fw-bold>
                This Privacy Notice governs all personal data collected when you use the Probinar platform, including:
                </h3> */}
                <p>This Privacy Notice governs all personal data collected when you use the Probinar platform, including:</p>
                <div>
                  <ul>
                    <li>
                      <strong style={{ color: 'black' }}>Mandatory data: </strong>Information required for registration, participation, and transactions.
                    </li>
                    <li>
                      <strong style={{ color: 'black' }}>Optional data: </strong>Data shared during surveys, promotional activities, or feedback.

                    </li>
                    <li> <strong style={{ color: 'black' }}>Automatically collected data: </strong>Usage data and analytics.</li>
                  </ul>
                  <p>This policy does not apply to third-party services, which are governed by their own privacy practices.</p>
                </div>
              </div>
              <div class="pb-3">
                <h2 class="fw-semibold">Legal Bases for Processing Personal Data
                </h2>
                <p>
                  We process personal data under the following lawful bases:
                </p>

                <div>
                  <ul>
                    <li>
                      <strong style={{ color: 'black' }}>Contractual Obligations: </strong>To provide services outlined in our Terms of Use.
                    </li>
                    <li>
                      <strong style={{ color: 'black' }}>Legal Compliance: </strong>To meet regulatory requirements and respond to legal requests.

                    </li>
                    <li> <strong style={{ color: 'black' }}>Legitimate Interests: </strong>For analytics, marketing, platform enhancements, and fraud prevention.</li>

                    <li> <strong style={{ color: 'black' }}>Consent: </strong>For activities requiring explicit permission, such as marketing communications.</li>

                  </ul>
                  {/* <p>This policy does not apply to third-party services, which are governed by their own privacy practices.</p> */}
                </div>
              </div>
              <div class="pb-3">
                <h2 class="fw-semibold">What Personal Data We Collect</h2>
                <h2 class="fw-semibold">Information You Provide Directly</h2>
                <ul>
                  <li>
                    <strong style={{ color: 'black' }}>Registration details: </strong>Name, email address, phone number, and password.
                  </li>
                  <li>
                    <strong style={{ color: 'black' }}>Course data: </strong>Assignments, quizzes, certificates, and participation records.

                  </li>
                  <li> <strong style={{ color: 'black' }}>Communication logs: </strong>Emails, customer support tickets, and surveys.</li>

                </ul>
                <h2 class="fw-semibold">Information We Collect Automatically</h2>
                <ul>
                  <li>
                    <strong style={{ color: 'black' }}>Usage data: </strong>Pages visited, time spent, and interactions with features.
                  </li>
                  <li>
                    <strong style={{ color: 'black' }}>Device details: </strong>IP address, browser type, operating system, and device identifiers.

                  </li>


                </ul>

                <h2 class="fw-semibold" >Information from Third Parties</h2>
                <ul>
                  <li>
                    <strong style={{ color: 'black' }}>Payment processors: </strong>To handle secure financial transactions.
                  </li>
                  <li>
                    <strong style={{ color: 'black' }}>Authentication providers: </strong>Data from Google, Facebook, or other login services.

                  </li>
                </ul>

                <h2 class="fw-semibold" >Sensitive Data</h2>
                <p>We do not intentionally collect sensitive personal data (e.g., health information, political beliefs). If such data is provided inadvertently, it will be handled with strict confidentiality. </p>
              </div>

              <h2 class="fw-semibold" >How We Use Personal Data</h2>
              <p>We use personal data for purposes including but not limited to:</p>

              <ul>
                <li>
                  <strong style={{ color: 'black' }}>Service Delivery: </strong>Providing educational content, tracking progress, and issuing certifications.
                </li>
                <li>
                  <strong style={{ color: 'black' }}>Personalization: </strong>Customizing learning paths and recommendations.
                </li>
                <li>
                  <strong style={{ color: 'black' }}>Communication: </strong>Sending updates, notifications, and marketing content.
                </li>
                <li>
                  <strong style={{ color: 'black' }}>Platform Improvement: </strong>Conducting analytics to improve user experience.
                </li>

                <li>
                  <strong style={{ color: 'black' }}>Compliance: </strong>Meeting legal and regulatory obligations.
                </li>
                <li>
                  <strong style={{ color: 'black' }}>Fraud Prevention: </strong>Monitoring and addressing suspicious activity.
                </li>
              </ul>

              <h2 class="fw-semibold" >Sharing Personal Data</h2>
              <p>We may share your personal data with:</p>

              <ul>
                <li>
                  <strong style={{ color: 'black' }}>Service Providers: </strong>Third-party vendors providing hosting, analytics, payment processing, and marketing services.
                </li>
                <li>
                  <strong style={{ color: 'black' }}>Business Partners: </strong>Affiliates and partners involved in course delivery or promotional campaigns.
                </li>
                <li>
                  <strong style={{ color: 'black' }}>Regulatory Authorities: </strong>To comply with legal or regulatory obligations.
                </li>

              </ul>
              <p>We do not sell your personal data to third parties for advertising purposes. </p>

              <div>
                <h2 class="fw-semibold">Retention of Personal Data</h2>
                <p>We retain personal data for as long as necessary to:</p>

                <ul>
                  <li>
                    Fulfill the purposes outlined in this notice.
                  </li>
                  <li>
                    Comply with legal and regulatory requirements.
                  </li>
                  <li>
                    Resolve disputes and enforce agreements.
                  </li>

                </ul>
                <p>Data no longer required will be securely deleted or anonymized. </p>
                <h2 class="fw-semibold">Confidentiality and Security of Personal Data</h2>
                <p>We implement stringent security measures to protect your data, including:</p>

                <ul>
                  <li>
                    <strong style={{ color: 'black' }}>Encryption: </strong>Secure storage and transmission of sensitive information.
                  </li>
                  <li>
                    <strong style={{ color: 'black' }}>Access Controls: </strong>Restricted access to data on a need-to-know basis.
                  </li>
                  <li>
                    <strong style={{ color: 'black' }}>Monitoring: </strong>Regular audits and system checks to identify vulnerabilities.
                  </li>

                </ul>
                <p>Despite these measures, no system is entirely secure. In the event of a data breach, affected users will be notified promptly.</p>
              </div>

              <div>
                <h2 class="fw-semibold">Your Rights</h2>
                <p>You have the right to:</p>

                <ul>
                  <li>
                    <strong style={{ color: 'black' }}>Access Your Data: </strong>Request a copy of the personal data we hold.
                  </li>
                  <li>
                    <strong style={{ color: 'black' }}>Rectify Inaccuracies: </strong>Correct incorrect or incomplete information.
                  </li>
                  <li>
                    <strong style={{ color: 'black' }}>Request Deletion: </strong>Ask for your data to be erased, subject to legal obligations.
                  </li>

                  <li>
                    <strong style={{ color: 'black' }}>Restrict Processing: </strong>Limit the use of your data under certain circumstances.
                  </li>
                  <li>
                    <strong style={{ color: 'black' }}>Data Portability: </strong>Receive your data in a structured, commonly used format.
                  </li>
                  <li>
                    <strong style={{ color: 'black' }}>Withdraw Consent: </strong>Revoke consent for processing activities reliant on it.
                  </li>

                </ul>

                <p>To exercise these rights, contact us at <strong style={{ color: 'black' }}>info@probinar.in</strong> </p>
              </div>

              <div>
                <h2 class="fw-semibold">Cookies and Tracking Technologies</h2>
                <p>We use cookies and similar technologies for purposes such as:</p>

                <ul>
                  <li>
                    Enhancing functionality and navigation.
                  </li>
                  <li>
                    Analyzing performance and usage patterns.
                  </li>
                  <li>
                    Delivering targeted advertisements (with your consent).
                  </li>

                </ul>
                <p>You can manage your cookie preferences through browser settings or opt out of targeted advertising.</p>
              </div>

              <div>
                <h2 class="fw-semibold">International Data Transfers</h2>
                <p>If you access our platform from outside India, your data may be transferred to and processed in India or other jurisdictions. We ensure appropriate safeguards for such transfers. </p>

              </div>

              <div>
                <h2 class="fw-semibold">Third-Party Links and Services</h2>
                <p>Our platform may include links to external websites or services. Probinar is not responsible for their privacy practices. We recommend reviewing their policies before sharing any information. </p>

              </div>

              <div>
                <h2 class="fw-semibold">Changes to Our Privacy Notice</h2>
                <p>We may update this Privacy Notice periodically. Any changes will be posted on this page with an updated effective date. Continued use of our services constitutes acceptance of the revised notice.   </p>

              </div>

              <div>
                <h2 class="fw-semibold">No Information from Children</h2>
                <p>Our services are not intended for individuals under 13 years of age. If we inadvertently collect data from children, it will be deleted promptly.</p>

              </div>


              <div>
                <h2 class="fw-semibold">Data Protection Officer</h2>
                <p>For data-related concerns, you may contact our Data Protection Officer at  <strong style={{ color: 'black' }}>contact@probinar.in</strong></p>

              </div>

              <div class="pb-3">
                <h2 class="fw-semibold">Contact Us</h2>
                <p>
                  For questions or concerns about this Privacy Notice or how we handle your data, please contact us: <strong style={{ color: 'black' }}>info@probinar.in</strong>

                </p>
                {/* <p>Contact info: info@probinar.in</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </>
  );
};

export default Privacypolicy;
