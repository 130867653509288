import React, { useState } from 'react';
import { toast } from "react-toastify";
import axios from 'axios';
import * as Yup from "yup";

import logo from '../assets/logo.svg'
import fb from '../assets/fb.svg';
import lin from '../assets/ln.svg';
import ig from '../assets/ig.svg';
import teleg from '../assets/teleg.svg';
import x from '../assets/x.svg';
import you from '../assets/you.svg';
import med from '../assets/med.svg'
import gmail from '../assets/gmail.svg'
import { Link } from 'react-router-dom';

const Footer = () => {
  const [mail, setMail] = useState('');
  const [errors, setErrors] = useState({ mail: '' });

  const schema = Yup.object().shape({
    mail: Yup.string()
      .email("Please Enter a Valid Email")
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMail(value);

    schema.validate({ [name]: value })
      .then(() => {
        setErrors({ ...errors, [name]: '' });
      })
      .catch((error) => {
        setErrors({ ...errors, [name]: error.errors[0] });
      });
  };


  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    schema.validate({ mail })
      .then(() => {
        axios.post('https://backend.probinar.in/newsletter/subscribe', { email: mail })
          .then((response) => {
            console.log("response", response);
            toast.success(response.data.message);
            setMail('');
          })
          .catch((error) => {
            setErrors({ ...errors, mail: 'Failed to send Email' });
            setMail('');
            console.error("Error while sending Email:", error);
          });
      })
      .catch((error) => {
        setErrors({ ...errors, mail: error.errors[0] });
        console.error("Validation error:", error);
      });
  };

  const handleScroll = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className='container-fluid footerbg'>
        <div className='p-3'>
          <div className='container'>
            <div className='d-lg-flex'>
              <div className='col-8'>
                <div className='d-lg-flex justify-content-around py-5 col-12'>

                  <div>
                    <h5>Menu</h5>
                    <div>
                      <p>
                        <Link to="/home" onClick={handleScroll} className='texhover'>Home</Link>
                      </p>
                      <p>
                        <Link to="/about-us" onClick={handleScroll} className='texhover'>About</Link>
                      </p>
                      <p>
                        <Link to="/contact-us" onClick={handleScroll} className='texhover'>Contact</Link>
                      </p>
                      <p>
                        <Link to="/course" onClick={handleScroll} className='texhover'>Courses</Link>
                      </p>
                      <p>
                        <Link to="/refund-and-cancellation" onClick={handleScroll} className='texhover'>Refund and Cancellation</Link>
                      </p>
                      <p>
                        <Link to="/shipping-and-delivery" onClick={handleScroll} className='texhover'>Shipping And  Delivery Policy</Link>
                      </p>

                    </div>
                  </div>
                  {/* <div>
                    <h5>Follow Us</h5>
                    <div>
                      <div>
                        <p>
                          <Link to="https://www.facebook.com/Probinar.offl" target='_blank' onClick={handleScroll} className='texhover'>Facebook</Link>
                        </p>
                        <p>
                          <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2FProbinar_offl" target='_blank' onClick={handleScroll} className='texhover'>Twitter</Link>
                        </p>
                        <p>
                          <Link to="https://www.instagram.com/probinar.offl/" target='_blank' onClick={handleScroll} className='texhover'>Instagram</Link>
                        </p>
                        <p>
                          <Link to="https://t.me/Probinar_channel" target='_blank' onClick={handleScroll} className='texhover'>Telegram</Link>
                        </p>
                      </div>

                    </div>
                  </div> */}
                  <div>
                    <h5>Custom Services</h5>
                    <div className=''>
                      <div>
                        <p>
                          <Link to="/terms-and-conditions" onClick={handleScroll} className='texhover'>Terms & Conditions</Link>
                        </p>
                        <p>
                          <Link to="/privacy-policy" onClick={handleScroll} className='texhover' target="_blank">
                            Privacy Policy
                          </Link>
                        </p>


                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className='col-lg-4'>
                <div className='py-5'>
                  <div className='d-flex justify-content-center'>
                    <img src={logo} alt='Probinar logo' className='col-7' draggable="false" />
                  </div>
                  <div className='add'>
                    <strong>Address:</strong>
                    <p>NO 38 TB ROAD BHARATHIDASAN SALAI<br />
                      ARASARADI<br />
                      MADURAI SOUTH<br />
                      MADURAI 625016 <br />
                      TAMILNADU INDIA
                    </p>
                  </div>
                  <div>
                    <p className='text-dark'><b>Newsletter</b></p>
                  </div>
                  <div className='d-flex rounded-3 col-lg-8 col-md-5 inputbox'>
                    <input
                      type='email'
                      name='mail'
                      value={mail}
                      className='border-0 bg-transparent'
                      onChange={handleChange}
                      autoComplete='off'
                      id="mail"
                    />
                    <button className='px-3 border-0 rounded-end py-2 text-light inputbutton' onClick={handleSubmit}>Subscribe</button>
                  </div>
                  <p id="error" className='text-danger'>{errors.mail}</p>

                  <div className='d-flex justify-content-start'>

                    <div className='pt-4
                    
                    
                    
                    
                    
                    
                    
                    
                     col-12 col-md-8'>
                      <div className='d-flex justify-content-between'>
                        <Link to="https://www.facebook.com/Probinar.offl" target='_blank' onClick={handleScroll} className='text-decoration-none text-secondary'>
                          <img alt='facebook' src={fb} draggable="false" />
                        </Link>
                        <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2FProbinar_offl" target='_blank' onClick={handleScroll} className='text-decoration-none text-secondary'>
                          <img alt='X' src={x} draggable="false" />
                        </Link>
                        <Link to="https://t.me/Probinar_channel" target='_blank' onClick={handleScroll} className='text-decoration-none text-secondary'>
                          <img alt='telegram' src={teleg} draggable="false" />
                        </Link>
                        <Link to="https://www.linkedin.com/company/probinar/" target='_blank' onClick={handleScroll} className='text-decoration-none text-secondary'>
                          <img alt='linkedin' src={lin} draggable="false" />
                        </Link>
                      </div>

                      <div className='d-flex justify-content-between pt-3'>
                        <Link to="https://www.instagram.com/probinar.offl/" target='_blank' onClick={handleScroll} className='text-decoration-none text-secondary'>
                          <img alt='instagram' src={ig} draggable="false" />
                        </Link>

                        <Link to="https://medium.com/@probinar.offl" target='_blank' onClick={handleScroll} className='text-decoration-none text-secondary'>
                          <img alt='facebook' src={med} draggable="false" />
                        </Link>
                        <Link to="info@probinar.in" target='_blank' onClick={handleScroll} className='text-decoration-none text-secondary'>
                          <img alt='X' src={gmail} draggable="false" />
                        </Link>
                        <Link to="https://www.youtube.com/@probinar" target='_blank' onClick={handleScroll} className='text-decoration-none text-secondary'>
                          <img alt='telegram' src={you} draggable="false" />
                        </Link>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer