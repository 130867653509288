import './App.css';
import Hero from './Components/Hero';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route } from "react-router-dom";
import About from './Components/Pages/About';
import Course from './Components/Pages/Course';
import Blogs from './Components/Pages/Blogs';
import Blogdetail from './Components/Pages/Blogdetail';
import Blogdetailb from './Components/Pages/Blogdetailb';
import Blogdetailc from './Components/Pages/Blogdetailc';
import Blogdetaild from './Components/Pages/Blogdetaild';
import Contact from './Components/Pages/Contact';
import Privacypolicy from './Components/Pages/Privacypolicy';
import Termsconditions from './Components/Pages/Termsconditions';
import RefundCancell from './Components/Pages/RefundCancell';
import ShippingDelivery from './Components/Pages/ShippingDelivery';
import Team from './Components/Pages/Team';
import Teamb from './Components/Pages/Teamb';


function App() {
  return (
    <>

      {/* <Routes>
        <Route path="*" element={<Termsconditions/>} />
        <Route path="/" element={<Hero />} />
        <Route path="/:id" element={<Hero />} />
        <Route path="/home" element={<Hero />} />
        <Route path="/terms" element={<Termsconditions/>} />
        <Route path="/privacy" element={<Privacypolicy/>} />
        <Route path="/aboutus" element={<About />} />
        <Route path="/course" element={<Course />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/blogdetail" element={<Blogdetail />} />
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/blockchainingoverment" element={<Blogdetailb />} />
        <Route path="/empoweringminds" element={<Blogdetailc/>} />
        <Route path="/Blockchaincertification" element={<Blogdetaild/>} />
        <Route path="/privacypolicy" element={<Privacypolicy/>} />
        <Route path="/TermsConditions" element={<Termsconditions/>} />
      </Routes> */}

<Routes>
        <Route path="*" element={<Termsconditions/>} />
        <Route path="/" element={<Hero />} />
        <Route path="/:id" element={<Hero />} />
        <Route path="/home" element={<Hero />} />
        <Route path="/terms" element={<Termsconditions/>} />
        <Route path="/privacy" element={<Privacypolicy/>} />
        <Route path="/about-us" element={<About />} />
        <Route path="/course" element={<Course />} />
        <Route path="/blog" element={<Blogs />} />
        <Route path="/why-blockchain-education-is-essential" element={<Blogdetail />} />
        <Route path="/contact-us" element={<Contact/>}/>
        <Route path="/blockchain-in-government" element={<Blogdetailb />} />
        <Route path="/journey-into-blockchain-education" element={<Blogdetailc/>} />
        <Route path="/how-blockchain-boost-your-career" element={<Blogdetaild/>} />
        <Route path="/privacy-policy" element={<Privacypolicy/>} />
        <Route path="/terms-and-conditions" element={<Termsconditions/>} />
        <Route path="/refund-and-cancellation" element={<RefundCancell/>} />
        <Route path="/shipping-and-delivery" element={<ShippingDelivery/>} />
        <Route path="/teams" element={<Team/>} />
        <Route path="/teamb" element={<Teamb/>} />
      </Routes>
    </>
  );
}

export default App;
